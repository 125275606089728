import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';

interface IncidentCountCardProps {
  incidentType: string;
  count: number;
}

const IncidentCountCard: React.FC<IncidentCountCardProps> = ({ incidentType, count }) => {
  return (
    <Card className="incident-count-card">
      <CardContent>
        <Typography variant="h6" component="h2">{incidentType}</Typography>
        <Typography variant="body2" component="p">{count}</Typography>
      </CardContent>
    </Card>
  );
};

export default IncidentCountCard;