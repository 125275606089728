import React from 'react'
import server from "../../services/server";
import {
    Button,
    CircularProgress, Divider,
    Snackbar,
    Stack,
} from "@mui/material";
import WebsitePreviewer, {ISourceSelectors} from "./WebsitePreviewer";
import {DataFieldRenderer, ENTITIES} from "../settings/entities";
import {DataItem, IDataField} from "../../services/types/entities";
import TelegramPreviewer from "./TelegramPreviewer";

interface IProps {
    id: number
}

interface ISourceConfig extends ISourceSelectors {
    title: string | null,
    source_type: "news_site" | "telegram"
    url: string | null,
    load_in_browser?: boolean,
    date_added?: string,
}
interface IState {
    loadingData: boolean,
    loadingError: string | null,
    data: null | ({ [key: string]: any } & ISourceConfig & DataItem),
    awaitingSave: boolean,
    saveNotification: string | null,
    loadingFrontPagePreview: boolean,
    frontPageHTML: string | null
}

class SourceManager extends React.Component<IProps, IState> {
    private endPoint = ENTITIES["SOURCES"].endpoint;
    private fields: IDataField[] = ENTITIES["SOURCES"].fields;

    constructor(props: IProps) {
        super(props);
        this.state = {
            loadingData: false,
            loadingError: null,
            data: null,
            awaitingSave: false,
            saveNotification: null,
            loadingFrontPagePreview: false,
            frontPageHTML: null
        }
    }

    async componentDidMount(){
        await this.fetchData();
    }

    async componentDidUpdate(prevProps:Readonly<IProps>){
        const prevId = prevProps.id;
        const currId = this.props.id;
        if(prevId !== currId){
            this.setState((curr)=>({...curr, loadingFrontPagePreview: false, frontPageHTML: null}), async ()=>{
                await this.fetchData()
            })
        }
    }

    private async fetchData() {
        if(this.state.loadingData){return}
        const currId = this.props.id;
        this.setState((curr)=>({...curr, loadingData: true}),async ()=>{
            const res = await server.get(this.endPoint + "/" + currId);
            if (res && !res.error) {
                this.setState((curr)=>({
                    ...curr, data: res,
                    loadingData: false, loadingError: null
                }))
            }
            else {
                this.setState((curr)=>({
                    ...curr, loadingData: false,
                    loadingError: res?.error || "error - failed to load data"
                }))
            }
        })
    }

    private editData(field: string, value: any){
        const data = this.state.data;
        if(!data){return}
        data[field] = value;
        this.setState((curr)=>({...curr, data}))
    }

    private async saveData(){
        if(this.state.awaitingSave || !this.state.data){return}
        this.setState((curr)=>({...curr, awaitingSave: true}), async ()=>{
            const data = this.state.data;
            const id = data?.id;
            if(id === undefined){
                this.setState((curr)=>({
                    ...curr, awaitingSave: false,
                    saveNotification: res?.error || "error - couldn't save data"
                }))
            }
            const res = await server.post(this.endPoint + "/" + id + "/",
                {...data}
            );
            if (res.success) {
                this.setState((curr)=>({
                    ...curr,
                    awaitingSave: false, saveNotification: "data saved",
                }), ()=>{
                    this.fetchData();
                })
            }
            else {
                this.setState((curr)=>({
                    ...curr, awaitingSave: false,
                    saveNotification: res?.error || "error - couldn't save data"
                }))
            }
        });
    }

    private getSaveNotifications(){
        const msg = this.state.saveNotification;
        return <Snackbar
            open={msg !== null}
            autoHideDuration={3000}
            onClose={()=>{this.setState((curr)=>(
                {...curr, saveNotification: null, awaitingSave: false}
            ))}}
            message={msg}
        />
    }

    private getDataEditor(): React.ReactElement {
        const data = this.state.data;
        if(data === null){
            return <CircularProgress/>
        }
        return <Stack direction={"column"} gap={1} alignItems={"left"} dir={"ltr"}>
            {
                this.fields.filter(f=>(!f.hideWhen || !f.hideWhen(data))).map((f) => {
                    return DataFieldRenderer[f.type](
                        f,
                        data[f.key],
                        (value) => {
                            this.editData(f.key, value);
                        }
                    )
                })
            }
        </Stack>
    }

    render() {
        const data = this.state.data;
        return <div>{
                    this.state.loadingData ? <CircularProgress/> : (
                        this.state.loadingError ? <span className={"data-load-error"}>
                            {this.state.loadingError}
                        </span> : <Stack
                            direction={"column"}
                            gap={1} divider={<Divider orientation="horizontal" flexItem />}
                        >
                            {this.getDataEditor()}
                            {
                                (data?.source_type === 'news_site' && data?.url) ? <WebsitePreviewer
                                    url={data?.url}
                                    load_in_browser={!!data?.load_in_browser}
                                    selectors={(data as ISourceSelectors)}
                                    setSelectors={(s: ISourceSelectors)=>{
                                        const sourceData = this.state.data;
                                        if(sourceData) {
                                            const newSourceData = {...sourceData, ...s};
                                            this.setState((curr)=>({...curr, data: newSourceData}))
                                        }
                                    }}
                                /> : null
                            }
                            {
                                (data?.source_type === 'telegram' && data?.url) ? <TelegramPreviewer
                                    url={data?.url}
                                /> : null
                            }
                            <div className={"save-section-wrap"}>
                            {
                                this.state.awaitingSave ?
                                    <CircularProgress/> :
                                    <Button
                                        onClick={async ()=>{
                                            await this.saveData();
                                        }}
                                        variant={"outlined"}
                                    >
                                        Save
                                    </Button>
                            }
                            </div>
                        </Stack>
                    )
                }
                {this.getSaveNotifications()}
        </div>
    }
}

export default SourceManager;