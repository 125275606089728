import React from 'react'
import "./scrape/Scrape.scss"
import {
    Button,
    CircularProgress, IconButton, LinearProgress,
    Stack,
    Tooltip, Typography,
} from "@mui/material";
import server from "../services/server";
import TopNavBar from "../UIComponents/TopNavBar/TopNavBar";
import {Cancel, Stop} from "@mui/icons-material";

interface IProps {
}

interface IState {
    jobId: null | number,
    awaitingFirstJobStatusCheck: boolean,
    awaitingScrapeInitiation: boolean,
    taskStatus: null | ITask,
    taskStatusCheckInterval: NodeJS.Timeout
}

interface ITask {
    id: number,
    job_id: number,
    task_title: string,
    task_size: number,
    task_progress: number,
    task_curr: string,
    task_status: string
}


export default class Scrape extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            jobId: null,
            taskStatus: null,
            awaitingFirstJobStatusCheck: true,
            awaitingScrapeInitiation: false,
            taskStatusCheckInterval: setInterval(async () => {
                await this.checkScrapingStatus()
            }, 1000)
        }
    }

    async componentDidMount() {
    }

    async componentWillUnmount(){
        if(this.state.taskStatusCheckInterval){
            clearInterval(this.state.taskStatusCheckInterval)
        }
    }

    private async checkScrapingStatus() {
        const res = await server.get("scrape/status/");
        if (res.error || res.job_id === null) {
            this.setState((current) => ({
                ...current,
                awaitingFirstJobStatusCheck: false,
                jobId: null
            }))
            return;
        }
        const taskStatus: ITask = res.task_status;
        const jobId = res.job_id
        this.setState((current) => ({
            ...current,
            taskStatus,
            awaitingFirstJobStatusCheck: false,
            jobId
        }))
    }

    private initiateScraping() {
        if (this.state.awaitingScrapeInitiation) {
            return
        }
        this.setState((current) => ({...current, awaitingScrapeInitiation: true}), async () => {
            const res = await server.post("scrape/", {});
            if (res.error) {
                this.setState((current) => ({...current, awaitingScrapeInitiation: false, jobId: null}))
                return;
            }
            this.setState((current) => ({...current, awaitingScrapeInitiation: false}))
        })
    }


    private async haltJob() {
        if (this.state.jobId === null) {
            return
        }
        await server.post("scrape/cancel/", {job_id: this.state.jobId});
        this.setState({jobId: null})
    }

    render() {
        const jobId = this.state.jobId;
        const taskStatus = this.state.taskStatus;
        const currTaskStep = taskStatus?.task_curr || "";
        return <div className={"page-wrap"}>
            <TopNavBar>
                <div className="title-wrap">
                    <span className="title-lang">איסוף נתונים</span>
                </div>
            </TopNavBar>
            <div className={"page-content"}>
                <div className={"scraping-manager-wrap"}>
                    {
                        !this.state.awaitingFirstJobStatusCheck ?
                            jobId !== null ?
                                <div className={"task-status-wrap"}>{
                                    <div className={"task-status"}>
                                        <Stack
                                            direction={"row"}
                                            gap={2} dir={"ltr"}
                                            alignItems={"center"}
                                            justifyContent={"space-between"}
                                            sx={{width: "100%"}}
                                        >
                                            <CircularProgress/>
                                            <h1
                                                className={"task-title"}>
                                                {"Job #" + jobId}
                                            </h1>
                                            <Tooltip title={"Cancel Scraping Job"} arrow>
                                                <IconButton
                                                    onClick={async () => {
                                                        await this.haltJob()
                                                    }}
                                                    color={"error"}
                                                >
                                                    <Cancel/>
                                                </IconButton>
                                            </Tooltip>
                                        </Stack>
                                        {taskStatus ? <Stack
                                            sx={{width: "80%", textAlign: "center"}}
                                            justifyContent={"center"}
                                        >
                                            <Tooltip
                                                title={"Processed: " + taskStatus.task_progress + " / " + taskStatus.task_size}
                                            >
                                                <LinearProgress
                                                    className={"task-progress"}
                                                    variant="determinate"
                                                    value={taskStatus.task_progress / taskStatus.task_size * 100}
                                                />
                                            </Tooltip>
                                            <h2>{taskStatus?.task_title || "No Active Task"}</h2>
                                            <Typography className={"task-step-description"}>{
                                                currTaskStep.trim()
                                            }</Typography>
                                        </Stack> : null}
                                    </div>
                                }</div> :
                                (
                                    this.state.awaitingScrapeInitiation ?
                                        <CircularProgress/> :
                                        <Button
                                            variant="contained"
                                            onClick={() => {
                                                this.initiateScraping()
                                            }}
                                        >
                                            Scrape
                                        </Button>
                                )
                            : <CircularProgress/>
                    }
                </div>
            </div>
        </div>
    }
}