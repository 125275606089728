import React from 'react'
import server from "../../services/server";
import {
    CircularProgress, List, Stack
} from "@mui/material";
import Paper from "@mui/material/Paper";
import {getTextDirection} from "../../services/multiLangUI";


interface IItemDetails {
    publication_date: string,
    body: string,
    permalink: string
}

interface IProps {
    url: string | null,
}

interface IState {
    loadingPreview: boolean,
    previewError?: string,
    entriesPreview: IItemDetails[] | null,
}


class TelegramPreviewer extends React.Component<IProps, IState> {
    private endPoint = "sources";

    constructor(props: IProps) {
        super(props);
        this.state = {
            loadingPreview: false,
            previewError: undefined,
            entriesPreview: null,
        }
    }

    async componentDidMount() {
        await this.previewSourceFrontPage();
    }

    async componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        const prevUrl = prevProps.url;
        const currUrl = this.props.url;
        if (prevUrl !== currUrl) {
            this.setState((curr) => ({
                ...curr,
                loadingFrontPagePreview: false, frontPageHTML: null, stage: "INIT"
            }), async () => {
                await this.previewSourceFrontPage();
            })
        }
    }

    private async previewSourceFrontPage() {
        if (this.state.loadingPreview || !this.props.url) {
            return
        }
        this.setState((curr) => ({
            ...curr, loadingPreview: true, previewError: undefined
        }), async () => {
            const res = await server.post(this.endPoint + "/preview/entries/", {
                url: this.props.url,
                source_type: "telegram"
            });
            if (res && !res.error) {
                this.setState((curr) => ({
                    ...curr, entriesPreview: res,
                    loadingPreview: false,
                    previewError: undefined
                }))
            } else {
                this.setState((curr) => ({
                    ...curr,
                    loadingPreview: false,
                    previewError: res.err || "Unknown Error Occurred"
                }))
            }
        })
    }

    private isValidHttpUrl = (str: string) => {
        let url;
        try {
            url = new URL(str);
        } catch (_) {
            return false;
        }
        return url.protocol === "http:" || url.protocol === "https:";
    }

    render() {
        return <Stack direction={"column"} className={"entries-preview-wrap"}>
            {this.state.loadingPreview ? <CircularProgress/> : null}
            {this.state.previewError ? <p>{this.state.previewError}</p> : null}
            {
                this.state.entriesPreview && this.state.entriesPreview.length ?
                    <Stack gap={1}>
                        {
                            this.state.entriesPreview.filter(m=>m.body.length).filter((m, i) => i < 10).map(e => {
                                return <Paper
                                    key={e.permalink}
                                    sx={{padding: "1em"}}
                                >
                                    <b>{e.publication_date}</b>
                                    <p
                                        dir={getTextDirection(e.body)}
                                        style={{textAlign: getTextDirection(e.body) === "ltr" ? "left" : "right"}}
                                    >
                                        {e.body}
                                    </p>
                                </Paper>
                            })
                        }
                    </Stack> :
                    (this.state.loadingPreview ? <p>No Articles Identified</p> : null)
            }
        </Stack>
    }
}

export default TelegramPreviewer;