import React, {Component} from 'react';
import {useNavigate} from 'react-router-dom';
import {Line} from 'react-chartjs-2';
import server from '../services/server';
import TopNavBar from '../UIComponents/TopNavBar/TopNavBar';
import {
    Card,
    CardContent,
    Typography,
    Box,
    Grid,
} from '@mui/material';
import IncidentCountCard from '../UIComponents/IncidentCountCard/IncidentCountCard';
import AlignDir from "../services/languages/AlignDir";
import {CHARTS, IChartDataRow} from "./charts/chartComponents";


interface ISystemSnapshot {
    "approved": number,
    "not approved": number,
    "removed": number,
    "total": number
}

interface IState {
    current_stats: ISystemSnapshot | null,
    trendline: IChartDataRow[] | null
}

interface IProps {
}

class IncidentsStatus extends Component<IProps, IState> {
    constructor(props: any) {
        super(props);
        this.state = {
            current_stats: null,
            trendline: null,
        };
    }

    async componentDidMount() {
        await this.fetchTrendlineData();
    }

    fetchTrendlineData = async () => {
        const response = await server.get('insights/snapshots/');
        if (response) {
            const trendline: IChartDataRow[] = response.snapshots.map((
                s: { id: number, snapshot: string, taken_at: string }
            ) => {
                const snapshot = JSON.parse(s.snapshot)
                const date_taken_at = new Date(s.taken_at).toISOString().split('T')[0]
                return Object.keys(snapshot).map((key: string) => {
                    return {
                        primary_variable: date_taken_at,
                        secondary_variable: key,
                        count: snapshot[key]
                    }
                })
            }).flat()
            this.setState({current_stats: response.current_stats, trendline})
        }
    };

    render() {
        const {
            current_stats
        } = this.state;

        return (
            <div className={"page-wrap"}>
                <TopNavBar>
                    <div className="title-wrap">
                        Incidents Status
                    </div>
                </TopNavBar>
                <div className={"page-content content-wrap"}>
                    <AlignDir direction={"ltr"}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6} md={3}>
                                <IncidentCountCard
                                    incidentType="Total Incidents"
                                    count={current_stats?.total || 0}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <IncidentCountCard
                                    incidentType="Approved Incidents"
                                    count={current_stats?.approved || 0}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <IncidentCountCard
                                    incidentType="Not Approved Incidents"
                                    count={current_stats?.["not approved"] || 0}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <IncidentCountCard
                                    incidentType="Removed Incidents"
                                    count={current_stats?.removed || 0}
                                />
                            </Grid>
                        </Grid>
                        <Card sx={{marginTop: 3, width: '100%'}}>
                            <CardContent>
                                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                    <Typography variant="h5" component="h2">
                                        Incident Trendline
                                    </Typography>
                                </Box>
                                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                    <Typography variant="body2" sx={{fontSize: '0.8rem'}}>
                                        Click on the color tabs to choose the status of your chart
                                    </Typography>
                                </Box>
                                <Box sx={{width: '100%', height: 800}}>
                                    {
                                        this.state.trendline ? CHARTS['LINE'].renderer({
                                            data: this.state.trendline,
                                            hideEmptyData: "HIDE"
                                        }) : null
                                    }
                                </Box>
                            </CardContent>
                        </Card>
                    </AlignDir>
                </div>
            </div>
        );
    }
}

const IncidentsStatusWithNavigate = (props: any) => {
    const navigate = useNavigate();
    return <IncidentsStatus {...props} navigate={navigate}/>;
};

export default IncidentsStatusWithNavigate;
