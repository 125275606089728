import TopNavBar from '../UIComponents/TopNavBar/TopNavBar';
import React, {Component} from 'react';
import PreferencesManager from "./preferences/preferencesManager";

class AIScreeningConfig extends Component<{}, {}> {
    constructor(props: {}) {
        super(props);
        this.state = {};
    }

    render() {
        return <div className={"page-wrap"}>
            <TopNavBar>
                <div className="title-wrap">
                    Settings
                </div>
            </TopNavBar>
            <div className={"page-content content-wrap"}>
                <PreferencesManager
                    visibleSettings={[
                        "AI_SCREENING",
                        "GPT_MODEL_SCREENING",
                        "AI_SCREENING_DESCRIPTION_SHORT",
                        "AI_SCREENING_DESCRIPTION_LONG",
                        "AI_SCREENING_EXAMPLES"
                    ]}
                />
            </div>
        </div>
    }
}

export default AIScreeningConfig;