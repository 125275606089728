import React, { Component } from 'react';
import { Tooltip, Box } from '@mui/material';
import { SxProps, Theme } from '@mui/system';

interface ISection {
  amount: number;
  title: string;
  color: string;
}

interface IProps {
  sections: ISection[];
}

class ProportionsBar extends Component<IProps> {
  getTotalAmount(): number {
    return this.props.sections.reduce((total, section) => total + section.amount, 0);
  }

  getColor(color: string, theme: Theme): string {
    const muiColors = ['success', 'error', 'primary', 'secondary', 'info', 'warning'];
    return muiColors.includes(color)
      ? theme.palette[color as keyof Theme['palette']].main
      : color; // Return hex code or custom color if not in MUI palette
  }

  render() {
    const totalAmount = this.getTotalAmount();

    return (
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: '1em',
          borderRadius: '3px',
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
          overflow: 'hidden',
        }}
      >
        {this.props.sections.map((section, index) => {
          const sectionWidth = `${(section.amount / totalAmount) * 100}%`;

          return (
            <Tooltip key={index} title={`${section.title}: ${section.amount}`}>
              <Box
                sx={{
                  width: sectionWidth,
                  backgroundColor: (theme) => this.getColor(section.color, theme),
                }}
              />
            </Tooltip>
          );
        })}
      </Box>
    );
  }
}

export default ProportionsBar;
