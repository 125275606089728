const rtlChars = /[\u0590-\u05FF\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB1D-\uFDFD\uFE70-\uFEFC]/;
const ltrChars = /[A-Za-z]/;

export const getTextDirection = (text?: string | null) => {
    try {
        if (!text) {
            return 'ltr'
        }
        const trimmedText = text.trim();

        for (let char of trimmedText) {
            if (rtlChars.test(char)) {
                return 'rtl';
            } else if (ltrChars.test(char)) {
                return 'ltr';
            }
        }
    } catch (e) {
        return 'ltr';
    }

    // Default to LTR if no significant characters are found
    return 'ltr';
};