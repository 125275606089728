import TopNavBar from '../UIComponents/TopNavBar/TopNavBar';
import React, {Component} from 'react';
import PreferencesManager from "./preferences/preferencesManager";
import {MenuItem, Select, Stack, Typography} from "@mui/material";
import {Translate} from "@mui/icons-material";
import {langProperties, Language} from "../services/languages/LanguageContext";
import withLanguage, {ILanguageProps} from "../services/languages/withLanguage";
import AlignDir from "../services/languages/AlignDir";


interface IProps extends ILanguageProps {
}

class Preferences extends Component<IProps, {}> {
    constructor(props: IProps) {
        super(props);
        this.state = {};
    }

    render() {
        return <div className={"page-wrap"}>
            <TopNavBar>
                <div className="title-wrap">
                    Settings
                </div>
            </TopNavBar>
            <AlignDir direction={"ltr"}>
                <div className={"page-content content-wrap"}>
                    <Stack spacing={3}>
                        <Typography variant="h4">Personal Preferences</Typography>
                        <Stack direction={"row"} spacing={2} alignItems={"center"}>
                            <Translate/>
                            <Typography variant="h6">Language</Typography>
                        </Stack>
                        <Select
                            value={this.props.language}
                            onChange={(e) => {
                                this.props.setLanguage((e.target.value as Language))
                            }}
                        >
                            {
                                Object.keys(langProperties).map((l: string) => {
                                    const langCode = (l as Language)
                                    return <MenuItem
                                        value={langCode}
                                        key={langCode}
                                    >
                                        {langProperties[langCode].title}
                                    </MenuItem>
                                })
                            }
                        </Select>
                        <Typography variant="h4">System Settings</Typography>
                        <PreferencesManager
                            visibleSettings={[
                                "OPENAI_API_KEY",
                                "GPT_MODEL_ANALYSIS",
                                "INCLUSION_RULES",
                                "EXCLUSION_RULES",
                                "AI_SCREENING",
                                "GPT_MODEL_SCREENING",
                                "GOOGLE_MAPS_API_KEY",
                                "TELEGRAM_API_ID",
                                "TELEGRAM_API_HASH"
                            ]}
                        />
                    </Stack>
                </div>
            </AlignDir>
        </div>
    }
}

export default withLanguage(Preferences);