import {incidentStatus} from "./incidentStatus";
import {
    MRT_ColumnFilterFnsState,
    MRT_ColumnFiltersState,
    MRT_PaginationState,
    MRT_SortingState
} from "material-react-table";
import {DEFAULT_INCIDENTS_PER_PAGE} from "../../pages/incident_browser/IncidentTable";
import rison from "rison";
import {removeUndefinedValues} from "../utils";

export interface IWorkspace {
    columnFilters: MRT_ColumnFiltersState,
    columnFilterFns: MRT_ColumnFilterFnsState,
    globalFilter: string,
    sorting: MRT_SortingState,
    status: incidentStatus[] | null
}

export interface IBrowseWorkspace extends IWorkspace {
    pagination: MRT_PaginationState,
}

export interface ICurationSessionWorkspace extends IWorkspace {
    history: number[],
    historyPos?: number
}

export const browseToCurationWorkspace = (browseWorkspace:IBrowseWorkspace, incidentId?: number): ICurationSessionWorkspace => {
    return {
        columnFilters: browseWorkspace.columnFilters,
        columnFilterFns: browseWorkspace.columnFilterFns,
        globalFilter: browseWorkspace.globalFilter,
        sorting: browseWorkspace.sorting,
        status: browseWorkspace.status,
        history: incidentId === undefined ? [] : [incidentId],
        historyPos: 0
    }
}

export const getDefaultBrowseWorkspace = (status: incidentStatus[]): IBrowseWorkspace => {
    const page_size_pref = localStorage.getItem("incident_table_page_size")
    return {
        columnFilterFns: {},
        columnFilters: [ { "id": "approved", "value": status } ],
        globalFilter: "",
        pagination: {
            pageSize: page_size_pref ? parseInt(page_size_pref) : DEFAULT_INCIDENTS_PER_PAGE,
            pageIndex: 0
        },
        sorting: [],
        status: status
    }
}

export const curationToBrowseWorkspace = (curationSessionWorkspace:ICurationSessionWorkspace): IBrowseWorkspace => {
    const page_size_pref = localStorage.getItem("incident_table_page_size")
    return {
        columnFilters: curationSessionWorkspace.columnFilters,
        columnFilterFns: curationSessionWorkspace.columnFilterFns,
        globalFilter: curationSessionWorkspace.globalFilter,
        sorting: curationSessionWorkspace.sorting,
        status: curationSessionWorkspace.status,
        pagination: {pageIndex: 0, pageSize: page_size_pref ? parseInt(page_size_pref) : DEFAULT_INCIDENTS_PER_PAGE,}
    }
}

interface IBrowseWorkspaceSearchParams {
    cf?: MRT_ColumnFiltersState,
    cff?: MRT_ColumnFilterFnsState,
    gf?: string,
    sr?: MRT_SortingState,
    st?: incidentStatus[] | null,
    p?: MRT_PaginationState
}

export const parseBrowseWorkspaceSearchParams = (workspaceParamStr: string | null):IBrowseWorkspace => {
    const page_size_pref = localStorage.getItem("incident_table_page_size")
    const workspaceParams:IBrowseWorkspaceSearchParams = workspaceParamStr ? (rison.decode(workspaceParamStr) as IBrowseWorkspaceSearchParams) : {}
    return {
        columnFilters: workspaceParams?.cf || ([] as MRT_ColumnFiltersState),
        columnFilterFns: workspaceParams?.cff || ({} as MRT_ColumnFilterFnsState),
        globalFilter: workspaceParams?.gf || "",
        pagination: workspaceParams?.p || ({
            pageSize: page_size_pref ? parseInt(page_size_pref) : DEFAULT_INCIDENTS_PER_PAGE,
            pageIndex: 0
        }),
        sorting: workspaceParams?.sr || ([] as MRT_SortingState),
        status: workspaceParams?.st || null
    }
}

export const encodeBrowseWorkspaceSearchParams = (workspace: IBrowseWorkspace):string => {
    const workspaceParams: IBrowseWorkspaceSearchParams = {}
    workspaceParams.p = (workspace.pagination.pageIndex === 0 &&
        workspace.pagination.pageSize === DEFAULT_INCIDENTS_PER_PAGE) ?
        undefined : workspace.pagination
    workspaceParams.sr = workspace.sorting?.length ? workspace.sorting : undefined;
    workspaceParams.gf = workspace.globalFilter?.length ? workspace.globalFilter : undefined;
    workspaceParams.cf = workspace.columnFilters.length ? workspace.columnFilters : undefined;
    workspaceParams.cff = workspace.columnFilterFns ? workspace.columnFilterFns : undefined;
    workspaceParams.st = workspace.status ? workspace.status : undefined;
    return rison.encode(removeUndefinedValues(workspaceParams));
}

interface ICurationWorkspaceSearchParams {
    cf?: MRT_ColumnFiltersState,
    cff?: MRT_ColumnFilterFnsState,
    gf?: string,
    sr?: MRT_SortingState,
    st?: incidentStatus[] | null,
    h?: number[],
    hp?: number
}

export const parseCurationSessionWorkspaceSearchParams = (workspaceParamStr:string|null):ICurationSessionWorkspace => {
    const workspaceParams:ICurationWorkspaceSearchParams = workspaceParamStr ? (rison.decode(workspaceParamStr) as ICurationWorkspaceSearchParams) : {}
    return {
        columnFilters: workspaceParams?.cf || ([] as MRT_ColumnFiltersState),
        columnFilterFns: workspaceParams?.cff || ({} as MRT_ColumnFilterFnsState),
        globalFilter: workspaceParams?.gf || "",
        history: workspaceParams.h || [],
        historyPos: workspaceParams.hp || 0,
        sorting: workspaceParams?.sr || ([] as MRT_SortingState),
        status: workspaceParams?.st || null
    };
}

export const encodeCurationSessionWorkspaceSearchParams = (workspace:ICurationSessionWorkspace):string => {
    const workspaceParams: ICurationWorkspaceSearchParams = {}
    workspaceParams.sr = workspace.sorting?.length ? workspace.sorting : undefined;
    workspaceParams.gf = workspace.globalFilter?.length ? workspace.globalFilter : undefined;
    workspaceParams.cf = workspace.columnFilters.length ? workspace.columnFilters : undefined;
    workspaceParams.cff = workspace.columnFilterFns.length ? workspace.columnFilterFns : undefined;
    workspaceParams.st = workspace.status ? workspace.status : undefined;
    workspaceParams.h = workspace.history?.length ? workspace.history : undefined;
    workspaceParams.hp = workspace.historyPos !== 0 ? workspace.historyPos : undefined;
    return rison.encode(removeUndefinedValues(workspaceParams));
}

