export const ColumnTypes = ["OBJECT" , "ARRAY" , "TEXT" , "DATE" , "SELECT" , "CITY" , "NUMBER"] as const;
export type  TColumnTypes =  typeof ColumnTypes[number]

export interface IBaseColumn {
    title: string,
    key: string,
    description: string,
    column_type: TColumnTypes,
    incident_identifier?: number,
    content_height?: number
}

export type IColumn = IBaseColumn | IColumnArray | IColumnObject | IColumnText | IColumnSelect | IColumnCity | IColumnNumber | IColumnDate

export interface IColumnObject extends IBaseColumn {
    properties: IColumn[]
}

export interface IColumnArray extends IBaseColumn {
    entries: IColumn
}

export interface IColumnText extends IBaseColumn {
    translation: string,
    multi_line: boolean,
    data_category?: null | string
}

export interface IColumnCity extends IBaseColumn {
    translation: string
}

export interface IColumnNumber extends IBaseColumn {
}

export interface IColumnDate extends IBaseColumn {
}

export interface IColumnSelect extends IBaseColumn {
    options: string[]
}