import React from 'react'
import "./WebsitePreviewer.scss"
import server from "../../services/server";
import {
    Button,
    CircularProgress, IconButton, List, ListItemButton, ListItemText, Stack, TextField
} from "@mui/material";
import {ArrowBack, HighlightAlt, Preview} from "@mui/icons-material";
import HTMLSelectorGenerator from "./HTMLSelectorGenerator";
import {getTextDirection} from "../../services/multiLangUI";
import dayjs from "dayjs";


export interface ISourceSelectors {
    title_selector?: string | null,
    date_selector?: string | null,
    body_selector?: string | null,
    article_entry_selector?: string | null
}

interface IItemDetails {
    title?: string,
    publication_date?: string,
    body?: string
}

interface IProps {
    url: string | null,
    load_in_browser: boolean,
    selectors: ISourceSelectors,
    setSelectors: (newSelectors: ISourceSelectors)=>any,
}

interface IState {
    stage: "INIT" | "FRONTPAGE" | "SINGLE_ITEM",
    selectors: ISourceSelectors,
    loadingFrontPagePreview: boolean,
    frontPagePreviewError?: string,
    frontPageHTML: string | null,
    loadingEntriesPreview: boolean,
    entriesPreviewError?: string,
    entriesPreview: {url: string, preview_title: string}[] | null,
    itemUrl: string | null,
    loadingItemPreview: boolean,
    itemPreviewError?: string,
    itemHTML: string | null,
    loadingItemDetailsPreview: boolean,
    itemDetailsPreviewError?: string,
    itemDetails: IItemDetails | null,
    focusedDetailsField: "title_selector" | "date_selector" | "body_selector" | null,
    samplingArticleSelectors: boolean,
}


class WebsitePreviewer extends React.Component<IProps, IState> {
    private endPoint = "sources";
    constructor(props: IProps) {
        super(props);
        this.state = {
            stage: "INIT",
            selectors: JSON.parse(JSON.stringify(props.selectors)),
            loadingFrontPagePreview: false,
            frontPageHTML: null,
            loadingEntriesPreview: false,
            entriesPreview: null,
            itemUrl: null,
            loadingItemPreview: false,
            itemHTML: null,
            loadingItemDetailsPreview: false,
            itemDetails:  null,
            focusedDetailsField: null,
            samplingArticleSelectors: false
        }
    }

    async componentDidMount(){}

    async componentDidUpdate(prevProps:Readonly<IProps>, prevState:Readonly<IState>, snapshot?:any){
        const prevUrl = prevProps.url;
        const currUrl = this.props.url;
        if(prevUrl !== currUrl){
            this.setState((curr)=>({
                ...curr,
                loadingFrontPagePreview: false, frontPageHTML: null, stage: "INIT"
            }), ()=>{
                this.previewSourceFrontPage();
            })
        }
        if(this.state.selectors.article_entry_selector !== this.props.selectors.article_entry_selector){
            const stage = this.state.stage === "SINGLE_ITEM" ? "FRONTPAGE" : this.state.stage;
            this.setState((curr)=>({
                ...curr,
                stage: stage, loadingEntriesPreview: false,
                selectors: JSON.parse(JSON.stringify(this.props.selectors))
            }), ()=>{
                this.previewSourceEntries();
            })
        }
        if(
            this.state.selectors.title_selector !== this.props.selectors.title_selector ||
            this.state.selectors.date_selector !== this.props.selectors.date_selector ||
            this.state.selectors.body_selector !== this.props.selectors.body_selector
        ){
            this.setState((curr)=>({
                ...curr,
                selectors: JSON.parse(JSON.stringify(this.props.selectors))
            }), ()=>{
                if(this.state.stage === "SINGLE_ITEM") {
                    this.previewArticleDetails();
                }
            })
        }
    }

    private async previewSourceFrontPage() {
        if(this.state.loadingFrontPagePreview || !this.props.url){return}
        this.setState((curr)=>({
            ...curr, loadingFrontPagePreview: true, frontPagePreviewError: undefined, stage: "FRONTPAGE"
        }),async ()=>{
            const res = await server.post(this.endPoint + "/preview/frontpage/", {
                url: this.props.url,
                load_in_browser: this.props.load_in_browser,
                source_type: "news_site"
            });
            if (res && !res.error) {
                this.setState((curr)=>({
                    ...curr, frontPageHTML: res,
                    loadingFrontPagePreview: false,
                    frontPagePreviewError: undefined
                }), async ()=>{
                    await this.previewSourceEntries();
                })
            }
            else {
                this.setState((curr)=>({
                    ...curr,
                    loadingFrontPagePreview: false,
                    frontPagePreviewError: res.err || "Unknown Error Occurred"
                }))
            }
        })
    }

    private async previewSourceEntries() {
        if(this.state.loadingEntriesPreview || !this.props.url){return}
        this.setState((curr)=>({
            ...curr, loadingEntriesPreview: true, entriesPreviewError: undefined
        }),async ()=>{
            const res = await server.post(this.endPoint + "/preview/entries/", {
                url: this.props.url,
                load_in_browser: this.props.load_in_browser,
                article_entry_selector: this.props.selectors.article_entry_selector,
                source_type: "news_site"
            });
            if (res && !res.error) {
                this.setState((curr)=>({
                    ...curr, entriesPreview: res,
                    loadingEntriesPreview: false,
                    entriesPreviewError: undefined
                }))
            }
            else {
                this.setState((curr)=>({
                    ...curr,
                    entriesPreview: null,
                    loadingEntriesPreview: false,
                    entriesPreviewError: res.err || "Unknown Error Occurred"
                }))
            }
        })
    }

    private previewSingleArticle() {
        if (this.state.loadingItemPreview || !this.state.itemUrl) {
            return
        }
        this.setState((curr) => ({
            ...curr, loadingItemPreview: true, itemPreviewError: undefined, stage: "SINGLE_ITEM"
        }), async () => {
            const res = await server.post(this.endPoint + "/preview/frontpage/", {
                url: this.state.itemUrl,
                load_in_browser: this.props.load_in_browser,
                source_type: "news_site"
            });
            if (res && !res.error) {
                this.setState((curr) => ({
                    ...curr, itemHTML: res,
                    loadingItemPreview: false,
                    itemPreviewError: undefined
                }), async () => {
                    await this.previewArticleDetails();
                })
            } else {
                this.setState((curr) => ({
                    ...curr,
                    itemHTML: null,
                    loadingItemPreview: false,
                    itemPreviewError: res.err || "Unknown Error Occurred"
                }))
            }
        })
    }

    private async previewArticleDetails() {
        if (this.state.loadingItemDetailsPreview || !this.state.itemUrl) {
            return
        }
        this.setState((curr) => ({
            ...curr, loadingItemDetailsPreview: true, itemDetailsPreviewError: undefined
        }), async () => {
            const res = await server.post(this.endPoint + "/preview/article_details/", {
                url: this.state.itemUrl,
                load_in_browser: this.props.load_in_browser,
                article_entry_selector: this.props.selectors.title_selector,
                date_selector: this.props.selectors.date_selector,
                body_selector: this.props.selectors.body_selector,
            });
            if (res && !res.error) {
                this.setState((curr) => ({
                    ...curr, itemDetails: res,
                    loadingItemDetailsPreview: false,
                    itemDetailsPreviewError: undefined
                }))
            } else {
                this.setState((curr) => ({
                    ...curr,
                    itemDetails: null,
                    loadingItemDetailsPreview: false,
                    itemDetailsPreviewError: res.err || "Unknown Error Occurred"
                }))
            }
        })
    }

    private isValidHttpUrl = (str: string) => {
        let url;
        try {
            url = new URL(str);
        } catch (_) {
            return false;
        }
        return url.protocol === "http:" || url.protocol === "https:";
    }

    private getFieldTitleWithSamplingToggle = (selector:  "title_selector" | "date_selector" | "body_selector" | null, label: string) => {
        return <Stack direction={"row"} alignItems={"center"} gap={2} dir={"ltr"}>
            <Button
                sx={{
                    "& .MuiButton-startIcon": {
                        marginRight: 0,
                        marginLeft: 0
                    },
                    minWidth: 0,
                    padding: "5px 5px"
                }}
                variant={
                    this.state.samplingArticleSelectors && this.state.focusedDetailsField === selector ? "contained" : "outlined"
                }
                onClick={() => {
                    if (this.state.focusedDetailsField === selector) {
                        this.setState((curr) => ({
                            ...curr,
                            focusedDetailsField: null,
                        }))
                    } else {
                        this.setState((curr) => ({
                            ...curr,
                            focusedDetailsField: selector,
                            samplingArticleSelectors: true
                        }))
                    }
                }}
                color="primary"
                startIcon={<HighlightAlt/>}
            />
            <span>{label}</span>
        </Stack>
    }

    render() {
        const url = this.props.url;
        return <Stack direction={"column"} gap={1}>
            <Button
                disabled={!url || !this.isValidHttpUrl(url)}
                variant="outlined"
                startIcon={<Preview/>}
                onClick={async () => {
                    await this.previewSourceFrontPage()
                }}
            >
                Preview and Identify Selectors
            </Button>
            {
                this.state.stage === "FRONTPAGE" ? <Stack direction={"column"}>
                        <h2>Frontpage ➜ Articles</h2>
                        <Stack direction={"row"} className={"entries-selection-wrap"}>
                            <Stack direction={"column"} className={"frontpage-preview-wrap"}>
                                {this.state.loadingFrontPagePreview ? <CircularProgress/> : null}
                                {this.state.frontPagePreviewError ? <p>{this.state.frontPagePreviewError}</p> : null}
                                {
                                    this.state.frontPageHTML ?
                                        <HTMLSelectorGenerator
                                            key={this.props.url || ""}
                                            src_html={this.state.frontPageHTML}
                                            selector={this.props.selectors.article_entry_selector || undefined}
                                            setSelector={(s) => {
                                                const newSelectors = this.props.selectors;
                                                newSelectors.article_entry_selector = s;
                                                this.props.setSelectors(newSelectors)
                                            }}
                                            singleSelect={false}
                                        /> :
                                        null
                                }
                            </Stack>
                            <Stack direction={"column"} className={"entries-preview-wrap"}>
                                {this.state.loadingEntriesPreview ? <CircularProgress/> : null}
                                {this.state.entriesPreviewError ? <p>{this.state.entriesPreviewError}</p> : null}
                                {
                                    this.state.entriesPreview && this.state.entriesPreview.length ?
                                        <List>
                                            {
                                                this.state.entriesPreview.map(e => {
                                                    return <ListItemButton
                                                        key={e.url}
                                                        onClick={() => {
                                                            this.setState((curr) => ({
                                                                ...curr,
                                                                stage: "SINGLE_ITEM",
                                                                itemUrl: e.url, itemHTML: null,
                                                            }), () => {
                                                                this.previewSingleArticle()
                                                            })
                                                        }}
                                                    >
                                                        <ListItemText
                                                            primary={e.preview_title}
                                                            secondary={e.url}
                                                            dir={getTextDirection(e.preview_title)}
                                                            sx={{
                                                                "& .MuiListItemText-primary": {
                                                                    "direction": getTextDirection(e.preview_title),
                                                                    "textAlign": getTextDirection(e.preview_title) === "rtl" ? "right" : "left"
                                                                }
                                                            }}
                                                        />
                                                    </ListItemButton>
                                                })
                                            }
                                        </List> :
                                        <p>No Articles Identified</p>
                                }
                            </Stack>
                        </Stack>
                    </Stack>
                    : null
            }
            {
                this.state.stage === "SINGLE_ITEM" ? <Stack direction={"column"}>
                        <Stack direction={"row"} gap={1} alignItems={"center"}>
                            <IconButton color={"primary"} size={"small"}
                                        onClick={()=>{
                                            this.setState((curr)=>({
                                                ...curr, stage: "FRONTPAGE",
                                                itemDetails: null
                                            }))
                                        }}
                            >
                                <ArrowBack/>
                            </IconButton>
                            <h2>Article ➜ Details</h2>
                        </Stack>
                        <Stack direction={"row"} className={"article-parsing-wrap"}>
                            <Stack direction={"column"} className={"article-preview-wrap"}>
                                {this.state.loadingItemPreview ? <CircularProgress/> : null}
                                {this.state.itemPreviewError ? <p>{this.state.itemPreviewError}</p> : null}
                                {
                                    this.state.itemHTML ?
                                        <HTMLSelectorGenerator
                                            key={this.state.itemUrl || ""}
                                            src_html={this.state.itemHTML}
                                            selector={this.state.focusedDetailsField ? this.state.selectors[this.state.focusedDetailsField] || undefined : undefined}
                                            setSelector={(s) => {
                                                if(this.state.focusedDetailsField && this.state.samplingArticleSelectors){
                                                    const newSelectors = this.props.selectors;
                                                    newSelectors[this.state.focusedDetailsField] = s;
                                                    this.props.setSelectors(newSelectors)
                                                    this.setState((curr)=>({...curr, samplingArticleSelectors: false}))
                                                }
                                            }}
                                            singleSelect={true}
                                        /> :
                                        null
                                }
                            </Stack>
                            <Stack direction={"column"} className={"article-details-wrap"}>
                                <p>Article Details</p>
                                {this.state.loadingItemDetailsPreview ? <CircularProgress/> : null}
                                {this.state.itemDetailsPreviewError ? <p>{this.state.itemDetailsPreviewError}</p> : null}
                                {
                                    this.state.itemDetails ?
                                        <Stack direction={"column"} gap={3}>
                                            <TextField
                                                label={this.getFieldTitleWithSamplingToggle("title_selector", "Title")}
                                                placeholder={"Title"}
                                                value={this.state.itemDetails?.title || ""}
                                                variant="outlined"
                                                multiline={true}
                                                dir={getTextDirection(this.state.itemDetails?.title || "")}
                                                onFocus={() => {
                                                    this.setState((curr) => ({
                                                        ...curr,
                                                        focusedDetailsField: "title_selector"
                                                    }))
                                                }}
                                            />
                                            <TextField
                                                className={"fill-width-util"}
                                                label={this.getFieldTitleWithSamplingToggle("date_selector", "Date")}
                                                placeholder={"Publication Date"}
                                                value={dayjs(this.state.itemDetails?.publication_date).format("YYYY-MM-DD") || null}
                                                onFocus={() => {
                                                    this.setState((curr) => ({
                                                        ...curr,
                                                        focusedDetailsField: "date_selector"
                                                    }))
                                                }}
                                            />
                                            <TextField
                                                label={this.getFieldTitleWithSamplingToggle("body_selector", "Content")}
                                                placeholder={"Content"}
                                                value={this.state.itemDetails?.body || ""}
                                                variant="outlined"
                                                multiline={true}
                                                dir={getTextDirection(this.state.itemDetails?.body || "")}
                                                onFocus={() => {
                                                    this.setState((curr) => ({
                                                        ...curr,
                                                        focusedDetailsField: "body_selector"
                                                    }))
                                                }}
                                            />
                                        </Stack> : null
                                }
                            </Stack>
                        </Stack>
                    </Stack>
                    : null
            }
        </Stack>
    }
}

export default WebsitePreviewer;