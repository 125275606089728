import React from 'react'
import {IColumnObject} from "../../../services/types/columns";
import BaseIncidentField from "./BaseIncidentField";
import IncidentField from "./IncidentField";

export default class IncidentFieldObject extends BaseIncidentField<IColumnObject> {
    render() {
        const column = this.props.column;
        const data = this.props.data;
        const emptyChecker = this.props.emptyChecker;
        const sources = this.props.sources;
        const onSourcesClick = this.props.onSourcesClick;
        const onEdit = this.props.onEdit;

        return <div className={"card-object-properties-wrap wide-field"}>
            {
                column.properties.map((p)=>{
                    return <React.Fragment key={p.key}>
                        <IncidentField
                            column={p}
                            data={data[p.key]}
                            citation={data["_source_" + p.key] || null}
                            sources={sources ? sources.map(s=> {
                                return {
                                    ...s,
                                    data: s.data ? s.data[p.key] : null,
                                    quote: s.data ? s.data["_source_" + p.key] || undefined : null
                                }
                            }) : null}
                            onEdit={
                                onEdit ? (v, f)=>{
                                    const inner_field = f ? p.key + ">" + f : p.key;
                                    data[p.key] = v;
                                    if (onEdit) {
                                        onEdit(data, inner_field);
                                    }
                                } : null
                            }
                            onClick={null}
                            onSourcesClick={onSourcesClick}
                            emptyChecker={emptyChecker}
                        />
                    </React.Fragment>
                })
            }
        </div>
    }
}