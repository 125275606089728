import React from 'react';
import './TopNavBar.scss';
import server from '../../services/server';
import cookie from 'js-cookie';

import {library} from '@fortawesome/fontawesome-svg-core'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faBars} from '@fortawesome/free-solid-svg-icons'
import withRouter from "../../services/withRouter";
import {encodeBrowseWorkspaceSearchParams, getDefaultBrowseWorkspace} from "../../services/types/workspace";
import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";

library.add(faBars);

interface IProps{
	location: any,
	navigate: any,
	params: any
	searchParams: any
	setSearchParams: any
	justification?: string
	children: JSX.Element
}
interface IState{
	emoji:string,
	menuOpened: boolean,
	permissions: any
}

export default withRouter(class TopNavBar extends React.Component<IProps, IState> {
	constructor(props:IProps) {
		super(props);
		this.state = {
			menuOpened: false,
			emoji: "🌹",
			permissions: {}
		};
	}

	componentDidMount() {
		this.setState((curr)=>({...curr, permissions: this.getPermissions()}))
	}

	getPermissions(){
		const json = cookie.get("permissions");
		if(!json)
			return {};
		try{
			return JSON.parse(json);
		}
		catch (e) {
			return {}
		}
	}

	logout(){
		cookie.set('token', "")
		server.get('login/logout/', {})
			.then(() => {
				this.props.navigate('/Login')
			});
	}

	toggleMenu = () => {
		if(!this.state.menuOpened){
			const possibleMenuEmojis = ["🌹"];
			const emoji = possibleMenuEmojis[Math.floor(Math.random()*possibleMenuEmojis.length)];
			this.setState((curr)=>({...curr, menuOpened: true, emoji: emoji}));
		}
		else{
			this.setState((curr)=>({...curr, menuOpened: false}));
		}
	}

	goToPage = (page:string, search?:{[key:string]: any}) => {
		this.props.navigate({
			pathname: "/" + page,
			search: search?Object.keys(search).map(k=>""+k+"="+search[k]).join("&"):""
		});
		this.toggleMenu();
	}

	getMenuItem(page: string, label: string, search?: { [key: string]: any }) {
		return (
			<a
				href={"/" + page}
				className="sidebar-item button"
				onClick={(e) => {
					this.goToPage(page, search || {})
					e.preventDefault()
				}}
			>
				<div>{label}</div>
			</a>
		);
	}

	render() {
    return (
        <div className="nav-bar-wrapper" style={{"justifyContent":(this.props.justification || "end")}}>
            <div className="nav-bar">
                <div className={"nav-bar-logo-menu-wrap " + (this.state.menuOpened ? "sidebar-open" : "")}>
                    <div className="nav-bar-icon" onClick={this.toggleMenu}>
                        <FontAwesomeIcon icon="bars"/>
                    </div>
                    <div className="sidebar-background" onClick={this.toggleMenu}/>
                    <div className={"sidebar-wrap"}>
                        <div className={"sidebar"}>
                            <div className="sidebar-title">
                                {this.state.emoji}
                            </div>
                            <div className="sidebar-options">
                                {this.getMenuItem("incidents", "תקריות למיון", {"s": encodeBrowseWorkspaceSearchParams(getDefaultBrowseWorkspace(["not approved"]))})}
                                {this.getMenuItem("incidents", "תקריות שאושרו", {"s": encodeBrowseWorkspaceSearchParams(getDefaultBrowseWorkspace(["approved"]))})}
                                {this.getMenuItem("incidents", "תקריות לא רלוונטיות", {"s": encodeBrowseWorkspaceSearchParams(getDefaultBrowseWorkspace(["removed"]))})}
                                {this.getMenuItem("charts", "נתונים")}
                                {this.getMenuItem("system-snapshots", "מצב מערכת")}
                                <Accordion>
                                    <AccordionSummary>
                                        <div className="sidebar-item">
                                            <b>הגדרות</b>
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {this.getMenuItem("sources", "מקורות")}
                                        {this.getMenuItem("keywords", "מילות מפתח")}
                                        {this.getMenuItem("exclusion-keywords", "כללי התעלמות")}
                                        {this.getMenuItem("ai-screening", "סינון כתבות חכם")}
                                        {this.getMenuItem("null-flags", "אינדיקטורים לדאטה חסר")}
                                        {this.getMenuItem("columns", "שדות")}
                                        {this.getMenuItem("scrape", "איתור תקריות ידני")}
                                        {this.getMenuItem("preferences", "העדפות")}
                                    </AccordionDetails>
                                </Accordion>
                                {this.getMenuItem("logout", "התנתקות מהמערכת")}
                            </div>
                        </div>
                    </div>
                </div>
                {this.props.children}
            </div>
        </div>
    )
}


})
