import React from 'react'
import "./IncidentSourcePreview.scss"
import 'react-resizable/css/styles.css';
import {IconButton, Paper, Stack, Tooltip} from "@mui/material";
import {IIncidentReport} from "../../services/types/incident";
import CancelIcon from "@mui/icons-material/Cancel";
import NewspaperIcon from '@mui/icons-material/Newspaper';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import {getTextDirection} from "../../services/multiLangUI";
import {Rnd} from "react-rnd";
import Highlighter from 'react-highlight-words';


export interface ISourcePreview {
    source: IIncidentReport,
    defaultPos: {x: number, y:number},
}

interface ISourcePreviewProps extends ISourcePreview {
    onClose: ()=>void,
}

interface IState {
    displayMode: "reader" | "original"
}


export default class SourcePreview extends React.Component<ISourcePreviewProps, IState> {
    constructor(props: ISourcePreviewProps) {
        super(props);
        this.state = {
            displayMode: "reader"
        }
    }

    async componentDidMount() {
    }

    render() {
        const source = this.props.source;
        const displayMode = this.state.displayMode;
        return <Rnd
            dragHandleClassName={"draggable-handle-" + source.id}
            default={{
                x: this.props.defaultPos.x,
                y: this.props.defaultPos.y,
                width: 300,
                height: 350,
            }}
            style={{
                pointerEvents: "all"
            }}
            enableResizing={true}
        >
            <Paper
                className={"source-preview-wrap"}
                sx={{
                    width: "100%",
                    display: "block"
                }}
            >
                <Stack gap={1} sx={{height: "100%"}}>
                    <div
                        className={
                            "source-preview-header-wrap " +
                            "draggable-handle-" + source.id}
                        dir={getTextDirection(source.post_title)}
                    >
                        <Stack direction={"row"} className={"source-preview-header"}>
                            <span>
                                {source.post_title || (source.website_title + ", " + source.post_publication_date)}
                            </span>
                            <Stack direction="row" gap={1}>
                                <Tooltip title={"סגירה"} arrow placement={"bottom"}>
                                    <IconButton
                                        aria-label="close"
                                        color={"error"}
                                        onClick={this.props.onClose}
                                    >
                                        <CancelIcon/>
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                        </Stack>
                    </div>
                    <div className={"source-preview-body"}>{
                        displayMode === "original" && source.post_html ?
                            <iframe
                                title={source.post_title}
                                srcDoc={source.post_html}
                                style={{width: "100%"}}
                            /> :
                            <div dir={getTextDirection(source.post_body)}>
                                {
                                    (source.post_body || "").split(/\n+/)
                                        .filter(paragraph => paragraph.trim() !== '')
                                        .map((paragraph, index) => (
                                            <p key={index}>
                                                <Highlighter
                                                    textToHighlight={paragraph}
                                                    searchWords={source.quote ? [source.quote] : []}
                                                />
                                            </p>
                                        ))
                                }
                            </div>
                    }</div>
                </Stack>
            </Paper>
        </Rnd>
    }
}