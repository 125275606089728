import React from 'react'
import {IconButton, Stack, Tooltip} from "@mui/material";
import {CheckCircle, ContentCopy, Link} from "@mui/icons-material";

interface IState {
    hover: boolean,
    copied: boolean
}

interface IProps {
    incidentId?: number
}

export default class IncidentPermalinkGetter extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            hover: false,
            copied: false
        }
    }

    async componentDidMount() {
    }

    private async copyLinkToClipboard() {
        const incidentId = this.props.incidentId;
        if(incidentId === undefined){return}
        const link = `${window.location.origin}/incident/${incidentId}`;
        await navigator.clipboard.writeText(link);
    }

    render() {
        if(this.props.incidentId === undefined){return null}
        return <div><Tooltip title={
            this.state.copied ? <Stack gap={1} justifyContent={"center"} alignItems={"center"}>
                <span>Link Copied to Clipboard</span>
                <CheckCircle/>
            </Stack> : "Copy Link to Incident"
        } arrow>
            <IconButton
                color={"primary"}
                size="small"
                component="div"
                onClick={async () => {
                    await this.copyLinkToClipboard()
                    this.setState((curr)=>({...curr, copied: true}))
                }}
                onMouseEnter={() => {
                    this.setState((curr) => ({...curr, hover: true, copied: false}))
                }}
                onMouseLeave={() => {
                    this.setState((curr) => ({...curr, hover: false, copied: false}))
                }}
            >
                {this.state.hover ? <ContentCopy/> : <Link/>}
            </IconButton>
        </Tooltip></div>
    }
}