import React, {Component} from "react";
import server from "../../services/server";
import {Assistant, FilterAlt, LocationOn, SettingsSuggest, Telegram} from "@mui/icons-material";
import {
    Box,
    Button,
    CircularProgress,
    FormControl,
    FormHelperText,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    Switch,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/Error";
import InfoIcon from "@mui/icons-material/Info";
import {Settings} from "@mui/icons-material";
import AlignDir from "../../services/languages/AlignDir";
import SelfContainedModal from "./selfContainedModal";

interface ISetting {
    name: string;
    type: 'string' | 'integer' | 'boolean' | 'select';
    title?: string;
    options?: string[];
    isVisible?: boolean | ((state: IState) => boolean);
    isDisabled?: boolean | ((state: IState) => boolean);
    description?: string;
    onChange?: (component: PreferencesManager) => Promise<void>;
    invalidCheck?: (value: any) => string | null | Promise<string | null>;
    placeholder?: string;
    multiline?: boolean;
    endAdornmentRenderer?: (component: PreferencesManager) => React.ReactElement | null;
}

interface ISection {
    title: string;
    icon?: React.ReactElement,
    settings: ISetting[];
}

const SECTIONS: ISection[] = [
    {
        title: 'OpenAI',
        icon: <Assistant/>,
        settings: [
            {
                name: 'OPENAI_API_KEY',
                type: 'string',
                title: 'OpenAI API Key',
                onChange: async (component: PreferencesManager) => {
                    await component.fetchOptions('GPT_MODEL_ANALYSIS');
                    await component.fetchOptions('GPT_MODEL_SCREENING');
                },
                invalidCheck: async (value) => {
                    return (value && value.length) ?
                        (
                            await verifySettingValue('OPENAI_API_KEY', value) ?
                                null :
                                'Invalid API Key'
                        ) :
                        'Required Field'
                }
            },
            {
                name: 'GPT_MODEL_ANALYSIS',
                type: 'select',
                title: 'Model to use for analyzing articles and producing incidents',
                invalidCheck: async (value) => {
                    return (value && value.length) ? null : 'Required Field'
                }
            },
        ]
    },
    {
        title: 'Screening',
        icon: <FilterAlt/>,
        settings: [
            {
                name: 'INCLUSION_RULES',
                type: 'boolean',
                title: 'Use inclusion rules to screen posts before analyzing',
                description: 'Use traditional keywords and / or regex rules to filter down posts based on their title, to save costs on AI analysis'
            },
            {
                name: 'EXCLUSION_RULES',
                type: 'boolean',
                title: 'Use exclusion rules to screen posts before analyzing',
                description: 'Use traditional block-words and / or regex rules to filter out irrelevant posts based on their title, to save costs on AI analysis'
            },
            {
                name: 'AI_SCREENING',
                type: 'boolean',
                title: 'Use AI to screen posts before analyzing',
                description: "Use AI to look at the titles of posts and pick the relevant ones, to save costs on AI analysis. Note that this step is associated with some costs as well.",
                endAdornmentRenderer: (component: PreferencesManager) => {
                    if (component.state.settings['AI_SCREENING'] === false) return null;
                    if (component.props.visibleSettings.includes('AI_SCREENING_DESCRIPTION_SHORT')) return null;
                    return <SelfContainedModal
                        content={(visibilitySetter) => {
                            return <PreferencesManager
                                visibleSettings={[
                                    "AI_SCREENING_DESCRIPTION_SHORT",
                                    "AI_SCREENING_DESCRIPTION_LONG",
                                    "AI_SCREENING_EXAMPLES"
                                ]}
                                onSave={() => {
                                    visibilitySetter(false)
                                }}
                            />
                        }}
                        trigger={(visibilitySetter) => {
                            return <IconButton onClick={() => {
                                visibilitySetter(true)
                            }}>
                                <Settings/>
                            </IconButton>
                        }}
                    />
                }
            },
            {
                name: 'GPT_MODEL_SCREENING',
                type: 'select',
                title: 'Model to use for screening posts',
                description: 'The model to use for screening posts. This model will be used to determine if a post is relevant or not. Usually, the cheapest available model should be picked here.',
                isVisible: state => state.settings.AI_SCREENING,
                invalidCheck: async (value) => {
                    return (value && value.length) ? null : 'Required Field'
                },
            },
        ]
    },
    {
        title: 'AI Screening',
        icon: <SettingsSuggest/>,
        settings: [
            {
                name: 'AI_SCREENING_DESCRIPTION_SHORT',
                type: 'string',
                title: 'Short description of the incident',
                description: "A brief explanation of the types of incidents the system should track.",
                placeholder: "'Incidents related to the use of firearms in civilian settings', 'workplace incidents', etc.",
                invalidCheck: async (value) => {
                    return (value && value.length) ? null : 'Required Field'
                },
            },
            {
                name: 'AI_SCREENING_DESCRIPTION_LONG',
                type: 'string',
                title: 'Detailed description of the incident',
                description: 'Use this field to provide additional details about the type of incidents you are looking for.',
                placeholder: 'Incidents related to the use of firearms which took place in Israel, and do not fall under the category of security/military clashes.',
                multiline: true,
                invalidCheck: async (value) => {
                    return (value && value.length) ? null : 'Required Field'
                }
            },
            {
                name: 'AI_SCREENING_EXAMPLES',
                type: 'string',
                title: 'Examples of relevant incidents',
                description: 'A few examples for the types of incidents you are looking for.',
                placeholder: 'Shootings, threats at gunpoint, bringing guns into civilian settings, guns going off accidentally, etc.',
                invalidCheck: async (value) => {
                    return (value && value.length) ? null : 'Required Field'
                }
            },
        ]
    },
    {
        title: 'Locations',
        icon: <LocationOn/>,
        settings: [
            {
                name: 'GOOGLE_MAPS_API_KEY',
                type: 'string',
                title: 'Google Maps API Key',
                invalidCheck: async (value) => {
                    return (value && value.length) ?
                        (
                            await verifySettingValue('GOOGLE_MAPS_API_KEY', value) ?
                                null :
                                'Invalid API Key'
                        ) :
                        'Required Field'
                }
            },
        ]
    },
    {
        title: 'Telegram',
        icon: <Telegram/>,
        settings: [
            {name: 'TELEGRAM_API_ID', type: 'string', title: 'Telegram API ID'},
            {name: 'TELEGRAM_API_HASH', type: 'string', title: 'Telegram API Hash'},
        ]
    }
];
const verifySettingValue = async (settingName: string, value: any) => {
    return await server.post(`settings/validate/${settingName}`, {value});
};

interface IProps {
    visibleSettings: string[];
    onSave?: (settings: { [key: string]: any }) => any;
}

interface IState {
    settings: { [key: string]: any };
    loading: boolean;
    options: { [key: string]: string[] }; // Add options to state
    loadingOptions: { [key: string]: boolean };
    validationStatus: { [key: string]: 'valid' | 'invalid' | 'validating' | null };
    validationErrors: { [key: string]: string | null };
}

class PreferencesManager extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            settings: {},
            loading: true,
            options: {
                GPT_MODEL_ANALYSIS: [],
                GPT_MODEL_SCREENING: []
            },
            loadingOptions: {},
            validationStatus: {},
            validationErrors: {}
        };
    }

    async componentDidMount() {
        await this.fetchSettings();
    }

    handleInputChange = async (setting: ISetting, value: any) => {
        this.setState(prevState => ({
            settings: {
                ...prevState.settings,
                [setting.name]: value,
                validationStatus: {
                    ...prevState.validationStatus,
                    [setting.name]: setting.invalidCheck ? 'validating' : null
                },
                validationErrors: {
                    ...prevState.validationErrors,
                    [setting.name]: null
                }
            }
        }), async () => {
            if (setting.onChange) {
                await setting.onChange(this);
            }
            if (setting.invalidCheck) {
                await this.validateSetting(setting, value);
            }
        });
    };

    validateSetting = async (setting: ISetting, value: any) => {
        if (!setting.invalidCheck) {
            return true;
        }
        const error = await setting.invalidCheck(value);
        this.setState(prevState => ({
            validationStatus: {
                ...prevState.validationStatus,
                [setting.name]: error ? 'invalid' : 'valid'
            },
            validationErrors: {
                ...prevState.validationErrors,
                [setting.name]: error
            }
        }));
    };

    fetchOptions = async (settingName: string) => {
        this.setState(prevState => ({
            loadingOptions: {
                ...prevState.loadingOptions,
                [settingName]: true
            }
        }));

        const options = await server.post(`settings/options/${settingName}`, this.state.settings);
        this.setState(prevState => ({
            options: {
                ...prevState.options,
                [settingName]: options
            },
            loadingOptions: {
                ...prevState.loadingOptions,
                [settingName]: false
            }
        }));
    };

    saveSettings = async () => {
        const settings = this.state.settings;
        await server.post("settings/", settings);
        if (this.props.onSave) {
            this.props.onSave(settings);
        }
        await this.fetchSettings();
    };

    fetchSettings = async () => {
        const allSettings: { [key: string]: any } = await server.get("settings/");
        const settings = Object.fromEntries(
            Object.entries(allSettings)
                .filter(([key]) => this.props.visibleSettings.includes(key))
        )
        this.setState({settings, loading: false}, async () => {
            /* Fetch options for GPT models */
            if (this.props.visibleSettings.includes('GPT_MODEL_ANALYSIS'))
                await this.fetchOptions('GPT_MODEL_ANALYSIS');
            if (this.props.visibleSettings.includes('GPT_MODEL_SCREENING'))
                await this.fetchOptions('GPT_MODEL_SCREENING');
            /* Validate all settings */
            SECTIONS.forEach(section => {
                section.settings.forEach(setting => {
                    if (this.props.visibleSettings.includes(setting.name)) {
                        if (setting.invalidCheck) {
                            this.validateSetting(setting, this.state.settings[setting.name]);
                        }
                    }
                });
            })
        });
    };

    renderSettingInput = (setting: ISetting) => {
        const currentSetting = this.state.settings[setting.name];
        const value = currentSetting || "";

        const isVisible = typeof setting.isVisible === 'function' ?
            setting.isVisible(this.state) :
            setting.isVisible !== false;
        const isDisabled = typeof setting.isDisabled === 'function' ?
            setting.isDisabled(this.state) :
            setting.isDisabled === true;

        const validationStatus = this.state.validationStatus[setting.name];
        const validationError = this.state.validationErrors[setting.name];

        if (!isVisible) {
            return null;
        }

        const getValidationIcon = () => {
            if (validationStatus === 'validating') {
                return <CircularProgress size={20}/>;
            }
            if (validationStatus === 'valid') {
                return <CheckIcon style={{color: 'green'}}/>;
            }
            if (validationStatus === 'invalid') {
                return <ErrorIcon style={{color: 'red'}}/>;
            }
            return null;
        };

        const getValidationStyle = () => {
            if (validationStatus === 'valid') {
                return {borderColor: 'green'};
            }
            if (validationStatus === 'invalid') {
                return {borderColor: 'red'};
            }
            return {};
        };

        return (
            <div style={{display: 'flex', alignItems: 'center'}}>
                {setting.description && (
                    <Tooltip title={<Typography>{setting.description}</Typography>} arrow>
                        <IconButton>
                            <InfoIcon/>
                        </IconButton>
                    </Tooltip>
                )}
                <Stack
                    direction={"row"}
                    gap={1}
                    sx={{width: "100%"}}
                    alignContent={"center"}
                >
                    <Box sx={{width: "100%"}}>
                    {(() => {
                        switch (setting.type) {
                            case 'string':
                            case 'integer':
                                return (
                                    <TextField
                                        fullWidth
                                        label={setting.title}
                                        value={value}
                                        onChange={(e) => this.handleInputChange(setting, setting.type === 'integer' ? parseInt(e.target.value, 10) : e.target.value)}
                                        disabled={isDisabled}
                                        error={validationStatus === 'invalid'}
                                        helperText={validationError}
                                        placeholder={setting.placeholder} // Use placeholder property
                                        multiline={setting.multiline} // Use multiline property
                                        InputProps={{
                                            endAdornment: getValidationIcon(),
                                            style: getValidationStyle()
                                        }}
                                    />
                                );
                            case 'select':
                                const options = this.state.options[setting.name] || setting.options || [];
                                const isLoading = this.state.loadingOptions[setting.name];
                                return (
                                    <FormControl fullWidth disabled={isDisabled || isLoading}>
                                        <InputLabel>{setting.title}</InputLabel>
                                        <Select
                                            label={setting.title}
                                            value={value}
                                            onChange={(e) => this.handleInputChange(setting, e.target.value)}
                                            endAdornment={isLoading ?
                                                <CircularProgress size={20}/> : getValidationIcon()}
                                            IconComponent={isLoading || getValidationIcon() ? ()=>null : undefined}
                                            style={getValidationStyle()}
                                        >
                                            <MenuItem value={""}>-</MenuItem>
                                            {options.map((option) => (
                                                <MenuItem key={option} value={option}>
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {validationError && <FormHelperText error>{validationError}</FormHelperText>}
                                    </FormControl>
                                );
                            case 'boolean':
                                return (
                                    <Stack direction="row" alignItems="center" spacing={1}>
                                        <Typography>{setting.title}</Typography>
                                        <Switch
                                            checked={!!value}
                                            onChange={(e) => this.handleInputChange(setting, e.target.checked)}
                                            disabled={isDisabled}
                                        />
                                    </Stack>
                                );
                            default:
                                return null;
                        }
                    })()}
                    </Box>
                    <Box sx={{alignContent: 'center'}}>
                        {setting.endAdornmentRenderer ? setting.endAdornmentRenderer(this) : null}
                    </Box>
                </Stack>
            </div>
        );
    };

    render() {
        return <AlignDir direction={"ltr"}>
            {
                !this.state.loading ? <Stack spacing={4}>
                        {SECTIONS.map((section, index) => {
                            const visibleSettings = section.settings
                                .filter(s => this.props.visibleSettings.includes(s.name));
                            if (visibleSettings.length === 0) return null;
                            return <Stack key={index} spacing={3}>
                                <Stack direction={"row"} spacing={2} alignItems={"center"}>
                                    {section.icon}
                                    <Typography variant="h6">{section.title}</Typography>
                                </Stack>
                                {visibleSettings.map((setting) => (
                                    <div key={setting.name}>
                                        {this.renderSettingInput(setting)}
                                    </div>
                                ))}
                            </Stack>
                        })}
                        <Button variant="contained" color="primary" onClick={this.saveSettings}>
                            Save Settings
                        </Button>
                    </Stack> :
                    <Stack alignItems="center" justifyContent="center" height="100%">
                        <CircularProgress/>
                    </Stack>
            }
        </AlignDir>
    }
}

export default PreferencesManager;