import React from 'react'
import TopNavBar from '../UIComponents/TopNavBar/TopNavBar';
import withRouter, {IRouterProps} from "../services/withRouter";
import server from "../services/server";
import "./settings/settings.scss"
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    CircularProgress,
    IconButton,
    Pagination,
    Snackbar,
    Stack,
    styled,
    Tooltip,
    tooltipClasses,
    TooltipProps,
    Zoom
} from "@mui/material";
import {AddCircle, ExpandMore, RemoveCircle} from "@mui/icons-material";
import {DataItem, E_ENTITIES,} from "../services/types/entities";
import {DataFieldRenderer, ENTITIES} from "./settings/entities";
import Paper from "@mui/material/Paper";

interface IProps extends IRouterProps {
    entity: E_ENTITIES
}

interface IState {
    loadingData: boolean,
    loadingError: string | null,
    data: DataItem[],
    page: number
    deletedRows: number[],
    awaitingSave: boolean,
    saveNotification: string | null,
}

const HtmlTooltip = styled(({ className, children,...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }}  children={children}/>
))(({}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "transparent",
        boxShadow: "none",
    },
}));

const PAGE_SIZE = 15;

class EntitiesManager extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            loadingData: false,
            loadingError: null,
            data: [],
            page: 0,
            deletedRows: [],
            awaitingSave: false,
            saveNotification: null
        }
    }

    async componentDidMount(){
        await this.fetchData();
    }

    async componentDidUpdate(prevProps: IProps) {
        if (this.props.entity !== prevProps.entity) {
            this.setState({
                loadingData: false,
                loadingError: null,
                data: [],
                page: 0,
                deletedRows: [],
                awaitingSave: false,
                saveNotification: null
            }, async () => {
                await this.fetchData();
            })
        }
    }

    private async fetchData() {
        if(this.state.loadingData){return}
        this.setState((curr)=>({...curr, loadingData: true}),async ()=>{
            const res = await server.get(ENTITIES[this.props.entity].endpoint + "/");
            if (res && !res.error) {
                const data:DataItem[] = res.map(
                    (x:{[key: string]: any}&{id: number | null}) => {
                    const item:DataItem = {...x, _changed: false};
                    return item;
                });
                this.setState((curr)=>({
                    ...curr, data: data, deletedRows: [],
                    loadingData: false, loadingError: null
                }))
            }
            else {
                this.setState((curr)=>({
                    ...curr, loadingData: false,
                    loadingError: res?.error || "error - failed to load data"
                }))
            }
        })
    }

    private editData(row: number, field: string, value: any){
        const data = this.state.data.slice();
        if(data.length < row || row < 0){return}
        data[row][field] = value;
        data[row]._changed = true;
        this.setState((curr)=>({...curr, data}))
    }

    private addRow() {
        const data = this.state.data.slice();
        data.push({
            ...JSON.parse(JSON.stringify(ENTITIES[this.props.entity].defaultNewItem)),
            id: null,
            _changed: true
        })
        this.setState((curr)=>({...curr, data}))
    }

    private deleteData(rowIndex: number){
        const data = this.state.data.slice();
        if(rowIndex < 0 || rowIndex >= data.length){return}
        const row = data[rowIndex];
        const rowId = row?.id;
        const deletedRows = this.state.deletedRows.slice();
        if(rowId && !(rowId in deletedRows)) {
            deletedRows.push(rowId);
        }
        data.splice(rowIndex, 1);
        this.setState((curr)=>({...curr, deletedRows, data}))
    }

    private async saveData(){
        if(this.state.awaitingSave){return}
        this.setState((curr)=>({...curr, awaitingSave: true}), async ()=>{
            const data = this.state.data.slice().filter(x=>x._changed);
            const deletedRows = this.state.deletedRows.slice();
            const res = await server.post(ENTITIES[this.props.entity].endpoint + "/",
                {data: data, deleted_rows: deletedRows}
            );
            if (res.success) {
                this.setState((curr)=>({
                    ...curr,
                    awaitingSave: false, saveNotification: "data saved",
                }), ()=>{
                    this.fetchData();
                })
            }
            else {
                this.setState((curr)=>({
                    ...curr, awaitingSave: false,
                    saveNotification: res?.error || "error - couldn't save data"
                }))
            }
        });
    }

    private getSaveNotifications(){
        const msg = this.state.saveNotification;
        return <Snackbar
            open={msg !== null}
            autoHideDuration={3000}
            onClose={()=>{this.setState((curr)=>(
                {...curr, saveNotification: null, awaitingSave: false}
            ))}}
            message={msg}
        />
    }

    private getDataEditor():React.ReactElement {
        const page = this.state.page;
        const data = this.state.data.slice(page*PAGE_SIZE, (page + 1)*PAGE_SIZE);
        const pageCount = Math.ceil(this.state.data.length / PAGE_SIZE)
        const accordionHeaderGenerator = ENTITIES[this.props.entity].accordionHeader;
        const accordionBodyGenerator = (itemData:DataItem, itemUnPaginatedIndex: number) => {
            return <Stack direction={"column"} gap={1} alignItems={"left"} dir={"ltr"}>{
                ENTITIES[this.props.entity].fields
                    .filter((f)=>!f.hideWhen || !f?.hideWhen(itemData))
                    .map((f) => {
                        return DataFieldRenderer[f.type](
                            f,
                            itemData[f.key],
                            (value) => {
                                this.editData(itemUnPaginatedIndex, f.key, value);
                            }
                        )
                    })
            }
            </Stack>
        }
        return <Stack direction={"column"} gap={1} className={"settings-editor"}>
            {
                data.map((r, p_i)=>{
                    const i:number = (page*PAGE_SIZE) + p_i;
                    return <React.Fragment key={i}>
                        <HtmlTooltip
                            TransitionComponent={Zoom}
                            TransitionProps={
                                {
                                    style: {
                                        transformOrigin: "center left"
                                    }
                                }
                            }
                            title={
                                <Tooltip title={"הסרת רשומה"} arrow placement={"bottom"}>
                                    <IconButton
                                        aria-label="delete"
                                        color={"error"}
                                        onClick={()=> {this.deleteData(i)}}
                                    >
                                        <RemoveCircle/>
                                    </IconButton>
                                </Tooltip>
                            }
                            placement={"right-start"}
                        >
                            {
                                accordionHeaderGenerator ?
                                    <Accordion
                                        sx={ENTITIES[this.props.entity].sx?.(r)}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMore/>}
                                        >
                                            {accordionHeaderGenerator(r)}
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            {
                                                accordionBodyGenerator(r, i)
                                            }
                                        </AccordionDetails>
                                    </Accordion> :
                                    <Paper
                                        sx={ENTITIES[this.props.entity].sx?.(r)}
                                    >
                                        {accordionBodyGenerator(r, i)}
                                    </Paper>
                            }
                        </HtmlTooltip>
                    </React.Fragment>
                })
            }
            <Pagination dir={"ltr"} count={pageCount} page={page + 1} onChange={(_, page)=>{
                this.setState((curr)=>({...curr, page: page - 1}))
            }} />
            <div><Tooltip title={"הוספת רשומה"} arrow placement={"bottom"}>
                <IconButton
                    aria-label="add"
                    color={"primary"}
                    onClick={()=>{this.addRow()}}
                >
                    <AddCircle/>
                </IconButton>
            </Tooltip></div>
        </Stack>
    }

    render() {
        const entity = ENTITIES[this.props.entity];
        return <div className={"page-wrap"}>
            <TopNavBar>
                <div className="title-wrap">
                    {entity.title}
                </div>
            </TopNavBar>
            <div className={"page-content content-wrap"}>
                {
                    this.state.loadingData ? <CircularProgress/> : (
                        this.state.loadingError ? <span className={"data-load-error"}>
                            {this.state.loadingError}
                        </span> : <span>
                            {this.getDataEditor()}
                            <div className={"save-section-wrap"}>
                            {
                                this.state.awaitingSave ?
                                    <CircularProgress/> :
                                    <Button
                                        onClick={async ()=>{
                                            await this.saveData();
                                        }}
                                        variant={"outlined"}
                                    >
                                        Save
                                    </Button>
                            }
                            </div>
                        </span>
                    )
                }
                {this.getSaveNotifications()}
            </div>
        </div>
    }
}

export default (withRouter(EntitiesManager));