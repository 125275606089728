import React from 'react'
import TopNavBar from '../UIComponents/TopNavBar/TopNavBar';
import withRouter, {IRouterProps} from "../services/withRouter";
import IncidentViewer from "./incident/IncidentViewer";
import {IconButton, Stack, Tooltip} from "@mui/material";
import IncidentPermalinkGetter from "./incident/IncidentPermalinkGetter";
import {ListAltOutlined} from "@mui/icons-material";
import {encodeBrowseWorkspaceSearchParams, parseBrowseWorkspaceSearchParams} from "../services/types/workspace";

interface IProps extends IRouterProps {}
interface IState {
    id: number | null
}

class Incident extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        const id_param = this.props.params.id;
        const id = id_param === undefined ? null : parseInt(id_param);
        this.state = {
            id
        }
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        // TODO: fix URL changes not affecting incident viewer
        const id_param = this.props.params.id;
        const id = id_param === undefined ? null : parseInt(id_param);
        if(id !== this.state.id){
            this.setState({id})
        }
    }

    render() {
        const incidentId = this.state.id;
        const storedWorkspaceRison = localStorage.getItem("browse_workspace");
        const storedWorkspace= parseBrowseWorkspaceSearchParams(storedWorkspaceRison);
        return <div className={"page-wrap"}>
            <TopNavBar>
                <Stack direction={"row"} gap={2} alignItems={"center"}>
                    <div className="title-wrap">
                        פרטי תקרית
                    </div>
                    <Tooltip title={"Back to Incidents List"} arrow>
                    <a
                        href={"/incidents?s=" + encodeURIComponent(encodeBrowseWorkspaceSearchParams(storedWorkspace))}
                    >
                        <IconButton color={"primary"}>
                            <ListAltOutlined/>
                        </IconButton>
                    </a>
                    </Tooltip>
                    {incidentId === null ? null : <IncidentPermalinkGetter incidentId={incidentId}/>}
                </Stack>
            </TopNavBar>
            <div className={"page-content content-wrap"} dir={"rtl"}>
                {incidentId === null ? <span>Missing Incident Id</span> : <IncidentViewer
                    id={incidentId}
                />}
            </div>
        </div>
    }
}

export default (withRouter(Incident));